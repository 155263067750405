/* eslint-disable max-len */
import Button from 'components/Button';
import Layout from 'components/Layout';
import BACKEND_URL from 'constants/backendUrl';
import { useTranslation } from 'react-i18next';
import { SBoldSecondaryText } from 'styles/components';
import { SCenteredColumn } from 'styles/layout';

const AskSubscriptionPage = () => {
    const { t } = useTranslation();

    const handleRedirectToPayment = () => {
        window.location.href = `${BACKEND_URL}/api/payment/session`;
    };

    return (
        <Layout pageTitle={t('page.ask-subscription.meta-title')}>
            <SCenteredColumn>
                <h1>
                    <SBoldSecondaryText>{t('page.ask-subscription.title')}</SBoldSecondaryText>
                </h1>
                <p>
                    <SBoldSecondaryText>{t('page.ask-subscription.content.part1')}</SBoldSecondaryText>
                    <br />
                    <span>{t('page.ask-subscription.content.part2')}</span>
                    <br />
                    <span>{t('page.ask-subscription.content.part3')}</span>
                    <SBoldSecondaryText>{t('page.ask-subscription.content.part4')}</SBoldSecondaryText>
                    <span>{t('page.ask-subscription.content.part5')}</span>
                    <SBoldSecondaryText>{t('page.ask-subscription.content.part6')}</SBoldSecondaryText>
                    <span>{t('page.ask-subscription.content.part7')}</span>
                    <SBoldSecondaryText>{t('page.ask-subscription.content.part8')}</SBoldSecondaryText>
                </p>
                <Button onClick={handleRedirectToPayment}>{t('page.ask-payment.payment-button')}</Button>
            </SCenteredColumn>
        </Layout>
    );
};

export default AskSubscriptionPage;
